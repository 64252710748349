jQuery(document).ready(function ($) {
    console.log("oi");
    // prevent multiple clicks from generating multiple emails
    $("form.contact--form").blockFormMultipleSubmit();

    $('input').focus(function(){
        $(this).addClass('input--focus');
    });

    $('input').blur(function(){
        $(this).removeClass('input--focus');
    });

    $('textarea').focus(function(){
        $(this).addClass('input--focus');
    });

    $('textarea').blur(function(){
        $(this).removeClass('input--focus');
    });
 
    var emptyFieldValidation = '';
    if (document.documentElement.lang == 'fr') {
        emptyFieldValidation = 'Veuillez reseigner ce champ'
    } else {
        emptyFieldValidation = 'Please fill in this field'
    }
    var elements = document.getElementsByTagName("INPUT");
    for (var i = 0; i < elements.length; i++) {
        elements[i].oninvalid = function(e) {
            e.target.setCustomValidity("");
            if (!e.target.validity.valid) {
                e.target.setCustomValidity(emptyFieldValidation);
            }
        };
        elements[i].oninput = function(e) {
           e.target.setCustomValidity("");
        };
    } 

    messageField = document.getElementById('contact_message');
    messageField.oninvalid = function(e) {
        e.target.setCustomValidity("");
        if (!e.target.validity.valid) {
            e.target.setCustomValidity(emptyFieldValidation);
        }
    };
    messageField.oninput = function(e) {
       e.target.setCustomValidity("");
    };
 });