//SCSS
import '../libraries/slick/slick.css';
import '../libraries/slick/slick.js';
import '../sass/styles.scss';

//JS 
import './jquery';
import '../libraries/jquery-block-form-multiple-submit';
import './layout/header';
import './layout/slider';
import './layout/industry';
import './layout/contact';