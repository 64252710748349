jQuery(document).ready(function ($) {
	if ($('.slickslider').length > 0) {
		initSlider()
	}

	if ($('.slickslider--industry').length > 0) {
		initSlider_industry()
	}
});

function initSlider() {
	$('.slickslider').slick({
		dots: true,
		infinite: true,
		speed: 1000,
		autoplay: true,
		autoplaySpeed: 10000,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: false,
		appendDots: $('.dots')
	});
	$('.dots .slick-dots li').empty();
}


function initSlider_industry() {
	$('.slickslider--industry').slick({
		dots: true,
		infinite: true,
		speed: 1000,
		autoplay: true,
		autoplaySpeed: 5000,
		arrows: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: false,
		appendDots: $('.dots--industry'),
	});
	$('.dots--industry .slick-dots li').empty();
}