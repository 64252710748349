// *************************************************************************
// basic jQuery plugin to prevent forms from
//   being submitted multiple times due to multiple click on button
// example usage:
//   $("form#MyForm").blockFormMultipleSubmit();
// *************************************************************************
jQuery.fn.blockFormMultipleSubmit = function() {
    $(this).on('submit',function(e){
        if ($(this).data('submitperformed') === true) {
            // next form submits, simply cancel action
            e.preventDefault();
        } else {
            // on form first submit, add flag to identify
            $(this).data('submitperformed', true);
        }
    });
    return this;
};