jQuery(document).ready(function ($) {
 
  $('#js-aviation').mouseenter(function(){
    $('#js-industry').removeClass();
    $('#js-industry').addClass('js-aviation');
  });

  $('#js-defense').mouseenter(function(){
    $('#js-industry').removeClass();
    $('#js-industry').addClass('js-defense');
  });

  $('#js-medical').mouseenter(function(){
    $('#js-industry').removeClass();
    $('#js-industry').addClass('js-medical');
  });

  $('#js-rail').mouseenter(function(){
    $('#js-industry').removeClass();
    $('#js-industry').addClass('js-rail');
  });

  $('#js-transport').mouseenter(function(){
    $('#js-industry').removeClass();
    $('#js-industry').addClass('js-transport');
  });

  $('#js-industries').mouseenter(function(){
    $('#js-industry').removeClass();
    $('#js-industry').addClass('js-industry');
  });

});