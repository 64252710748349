jQuery(document).ready(function ($) {
	if ($('.js-mobile-menu-toggler').length > 0) {
		initMainMenuToggle();
		menuAutoClose();
	}

	//Check Scroll positions
	initListenPageScroll();

	//scroll to initial page position
	setTimeout(function(){
		if("" == window.location.hash.replace("#","")){
			return;
		}
		let elem = document.getElementById(window.location.hash.replace("#",""));
		if( $(elem).length ){
			$('html,body')
				.animate({scrollTop:$(elem).offset().top}, 500);
		}
	}, 100);

	//Smooth Scroll for anchors
	$('a[href*=\\#]').on('click', function(event){     
		$('html,body').animate({scrollTop:$(this.hash).offset().top - 82}, 200);
	});

});

function initMainMenuToggle() {
	$('.js-mobile-menu-toggler').on('click', function (event) {
		event.preventDefault();
		toggleNavClass();
	});
}

	function menuAutoClose(){
		 $('.menu--item').on('mouseup', function(event){
		 	if ($('.js-nav').hasClass('nav--open')) {
		 		toggleNavClass();
		 	}
		 });
	}

function toggleNavClass() {
		$('.js-nav').toggleClass('nav--open');
		$('.js-body').toggleClass('nav--open');
}

//Check Scroll position
function initListenPageScroll() {
	checkScrollPosition();
	window.addEventListener('scroll', checkScrollPosition);
}


function checkScrollPosition() {
	let top = document.documentElement && document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop;
	let about = $('#about').position().top - 82;
	let services = $('#services').position().top - 82;
	let certification = $('#certification').position().top - 82;
	let industry = $('#industry').position().top - 82;
	let contact = $('#contact').position().top - 82;

	//Make Menu smaller
	if (top > 82) {
		$('.js-header').addClass('header--scrolled');
	} else {
		$('.js-header').removeClass('header--scrolled');
	}

	//Remove Selected Menu
	$('.menu--contact').removeClass('menuSelected');
	$('.menu--industry').removeClass('menuSelected');
	$('.menu--certification').removeClass('menuSelected');
	$('.menu--services').removeClass('menuSelected');
	$('.menu--about').removeClass('menuSelected');

	//Select Menu based on scroll position
	if (top > contact) {
		$('.menu--contact').addClass('menuSelected');
	}
	else if (top > industry) {
		$('.menu--industry').addClass('menuSelected');
	}
	else if (top > certification) {
		$('.menu--certification').addClass('menuSelected');
	}
	else if (top > services) {
		$('.menu--services').addClass('menuSelected');
	}
	else if (top > about) {
		$('.menu--about').addClass('menuSelected');
	}
	
}

